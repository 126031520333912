import React from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"
import Seo from '../components/seo'
import Layout from '../layouts'

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text + '...'}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <Text>{children}</Text>
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
                <img src={node.data.target.fields.file['en-US'].url} />
            );
        }
    },

}

const Actu = () => {

    const data = useStaticQuery(graphql`
  query NewsQuery {
    allContentfulNewsFeed {
        edges {
          node {
            title
            news {
              slug
              articleTitle
              updatedAt
              body1 {
                json
              }
            }
          }
        }
      }
  }
  `)

    const title = data.allContentfulNewsFeed.edges[0].node.title;
    const newsList = data.allContentfulNewsFeed.edges[0].node.news;

    return (
        <Layout>
            <Seo
                title={title + ' | Dafinity'}
                description={title + ' | Dafintiy'}
            />
            <section className="common-wrapper mt-10 px-15 px-4-md">
                <header>
                    <h1>{title}</h1>
                </header>
                <div className="mb-20">
                    {
                        newsList && newsList.map(news => {

                            let date = new Date(news.updatedAt)
                            let previewContent = news.body1.json.content[0].nodeType !== "embedded-asset-block" ? news.body1.json.content[0] : news.body1.json.content[1]

                            let previewImage = news.body1.json.content.find(item => item.nodeType === "embedded-asset-block");

                            return (
                                <Link to={news.slug} key={news.id} className="bc-blue bs-solid bt-w-0 bl-w-0 br-w-0 bb-w-3 relative py-5 mb-3 flex align-items flex-col-xs">
                                    <div className="max-width-250-px mr-10 max-width-100-per-xs mb-10-xs">
                                        <div>{documentToReactComponents(previewImage, options)}</div>
                                    </div>
                                    <div className="w-full">
                                        <h4 className="underline mt-0 text-blue-dafinity">{news.articleTitle}</h4>
                                        <div className="article-content">{documentToReactComponents(previewContent, options)}</div>
                                        <footer className="flex justify-between items-center">
                                            <span className="f5 text-grey-6 font-bold">{date.toLocaleString('fr-FR', { timeZone: 'UTC' })}</span>
                                            <span className="button f6">Lire l'article ></span>
                                        </footer>
                                    </div>

                                </Link>
                            )
                        })
                    }
                </div>
            </section>
        </Layout>
    )
}

export default Actu